import React from "react";

import Grid from "@material-ui/core/Grid";

const DeckPage = () => (
  <Grid container justify="center" alignItems="center">
    <Grid item>
      <iframe
        title="ery Inc deck"
        src="https://docs.google.com/presentation/d/e/2PACX-1vQY7erhAnCxQG6XKtLANW2CYt6e_tB_LQQ9QJxOxXbIALxQPmuKjH3GKDZJaCSe8oAIGFetgx-zIEXY/embed?start=true&loop=false&delayms=5000"
        frameborder="0"
        width="1440"
        height="839"
        allowfullscreen="true"
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
      ></iframe>
    </Grid>
  </Grid>
);

export default DeckPage;
